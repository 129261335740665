//goole map styles
var styles = [
    {
    featureType: "water",
    elementType: "geometry",
    stylers: [{
        color: "#e9e9e9"
    }, {
        lightness: 17
    }]
}, {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{
        color: "#f5f5f5"
    }, {
        lightness: 20
    }]
}, {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [{
        color: "#ffffff"
    }, {
        lightness: 17
    }]
}, {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{
        color: "#ffffff"
    }, {
        lightness: 29
    }, {
        weight: .2
    }]
}, {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{
        color: "#ffffff"
    }, {
        lightness: 18
    }]
}, {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [{
        color: "#ffffff"
    }, {
        lightness: 16
    }]
}, {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{
        color: "#f5f5f5"
    }, {
        lightness: 21
    }]
}, {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{
        color: "#dedede"
    }, {
        lightness: 21
    }]
}, {
    elementType: "labels.text.stroke",
    stylers: [{
        visibility: "on"
    }, {
        color: "#ffffff"
    }, {
        lightness: 16
    }]
}, {
    elementType: "labels.text.fill",
    stylers: [{
        saturation: 36
    }, {
        color: "#333333"
    }, {
        lightness: 40
    }]
}, {
    elementType: "labels.icon",
    stylers: [{
        visibility: "off"
    }]
}, {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{
        color: "#f2f2f2"
    }, {
        lightness: 19
    }]
}, {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [{
        color: "#fefefe"
    }, {
        lightness: 20
    }]
}, {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [{
        color: "#fefefe"
    }, {
        lightness: 17
    }, {
        weight: 1.2
    }]
}];


//goole map
function initialize(lat, lang, id){
    var latlng = new google.maps.LatLng(lat,lang);

    var myOptions =
    {
        zoom: 14,
        center: latlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
    };

    map = new google.maps.Map(document.getElementById(id), myOptions);
    //map.setOptions({styles: styles});
    map.panBy(0, -40);


    var myMarker = new google.maps.Marker(
    {
        position: latlng,
        map: map,
        //icon: theme.googleMap.icon,
    });

    google.maps.event.addListener(myMarker , 'click', function() {
        window.location.href = theme.googleMap.link;
    });
    google.maps.event.addDomListener(window, "resize", function() {
        var center = map.getCenter();
        google.maps.event.trigger(map, "resize");
        map.setCenter(center);
    });
}

$(window).on('load', function(){
    if ( $("div").is("#map-canvas") ){
        initialize(theme.googleMap.lat, theme.googleMap.lang, "map-canvas");
    }
});